
<template>
  <div class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Retrieve Medical Record</h5>
      <div class="card loading-viewport">
        <div class="card-body">
          <medical-history-lookup-form @foundItem="showItem($event)" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import MedicalHistoryLookupForm from '@/components/forms/MedicalHistoryLookupForm.vue'

export default {
  name: "history-find",
  components:{
    MedicalHistoryLookupForm
  },
  data() {
    return {
      item:null
    }
  },
  methods:{
    showItem(item){
      this.$router.push({path:`/medical-histories/${item.id}`});
    },
  }
}

</script>
  
  